/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Marquee from 'vux/src/components/marquee/marquee';
import MarqueeItem from 'vux/src/components/marquee/marquee-item';
export default {
  name: 'Index',
  components: {
    Marquee: Marquee,
    MarqueeItem: MarqueeItem
  },
  data: function data() {
    return {
      navData: [],
      navTopData: [],
      messageList: [],
      newsHandler: '',
      newsFlag: 0,
      msgLen: 6,
      title: '',
      headFlag: '0',
      firstLogin: 1,
      navshow: false
    };
  },
  computed: {},
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    //在next中写处理函数
    next(function (vm) {
      return vm.wherefrom(from.name);
    });
  },
  watch: {
    navData: function navData() {
      this.$nextTick(function () {
        var Module = this.navData.some(function (item) {
          // 判断项应为获取的变量
          if (item.iconUrlId == '/multistageApproval') {
            return true;
          }
        });
        var num = this.$utils.Store.getItem('firstLogin'); //首次登录并且多级审批模块存在

        if (Module && num == 1) {
          this.navshow = true; //执行新手引导

          this.noviceGuidance();
        } else {
          this.cancelNav();
        }
      });
    }
  },
  methods: {
    wherefrom: function wherefrom() {
      this.firstLogin = this.$utils.Store.getItem('firstLogin');
      this.getHomeMenu();
    },
    //新手引导(多级审批)
    noviceGuidance: function noviceGuidance() {
      var bootModule = document.getElementById('/multistageApproval');
      var Itemtop = bootModule.getBoundingClientRect().bottom; //获取dom对象底边与顶部距离

      var Itemleft = bootModule.getBoundingClientRect().left; //获取dom对象左边距离左边的距离

      var Itemwidth = bootModule.offsetWidth; //width+border+padding 实际的宽度

      var GuideBox = document.getElementById('IndexGuideBox'); //获取蒙版dom对象

      var navBoxTop = document.getElementsByClassName('nav-box-top'); //获取小圆圈以及竖线 外边的dom对象

      var Item = document.getElementsByClassName('van-grid-item__content');
      bootModule.style.background = 'transparent'; //高亮背景颜色

      bootModule.style.zIndex = '502'; //蒙版是501  高出蒙版就可以显示蒙版上层

      bootModule.style.border = '1px dashed #fff'; //添加边框

      bootModule.style.padding = '0.04rem'; //边框与内容有个内边距

      bootModule.style.borderRadius = ' 0.06rem'; //边框圆角弧度

      GuideBox.style.top = parseInt(Itemtop) + 'px';
      navBoxTop[0].style.left = parseInt(Itemleft) + parseInt(Itemwidth / 2, 10) + 'px';

      for (var i = 0; i < Item.length; i++) {
        Item[i].style.borderRadius = '0.04rem';
      }
    },
    cancelNav: function cancelNav() {
      this.navshow = false;
      this.$utils.Store.setItem('firstLogin', 0);
      this.$utils.Store.setItem('nav', 0);
      this.firstLogin = 0;
    },
    nextNav: function nextNav() {
      this.$utils.Store.setItem('nav', 1);
      this.$router.push({
        path: '/multistageApproval',
        query: {
          nav: 1
        }
      });
    },
    newsTitle: function newsTitle(value) {
      return value.length > 13 ? value.substring(0, 12) + '…' : value;
    },
    indexnavClick: function indexnavClick(value) {
      var _this = this;

      if (value === '' || value === null) {
        this.$utils.Tools.toastShow('功能暂未开放');
      } else {
        setTimeout(function () {
          _this.$router.push(value);
        }, 100);
      }
    },
    navClick: function navClick(value) {
      var _this2 = this;

      if (value === '' || value === null) {
        this.$utils.Tools.toastShow('功能暂未开放');
      } else {
        setTimeout(function () {
          _this2.$router.push(value);
        }, 100);
      }
    },
    getMesageLen: function getMesageLen() {
      var _this3 = this;

      return this.$api.Sys.getSystemParams(0, this.$constant.msgShowLen).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          if (data === null) {
            return 3;
          } else {
            _this3.msgLen = +data.paramValue;
            return data.paramValue;
          }
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();

        return Promise.reject();
      });
    },
    getMesageData: function getMesageData(len) {
      var _this4 = this;

      this.$api.Message.getMessageData({
        pageIndex: 1,
        pageSize: 10,
        search: {
          userSerial: this.$utils.Store.getItem('userSerial')
        }
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);
        } else {
          _this4.messageList = data.records;

          if (_this4.messageList.length === 0) {
            _this4.newsFlag = 0;
          } else if (_this4.messageList.length <= len) {
            _this4.newsFlag = 1;
          } else {
            _this4.newsFlag = 2;
          }
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },
    moreClick: function moreClick() {
      this.$router.push('/AllPrograms');
    },
    //档案管理
    fileList: function fileList() {
      this.$router.push('/fileList');
    },
    //入职档案
    InductionInfo: function InductionInfo() {
      this.$router.push('/InductionInfo');
    },
    mesageClick: function mesageClick() {
      this.$router.push('/MessageList');
    },
    getHomeMenu: function getHomeMenu() {
      var _this5 = this;

      this.$utils.Tools.loadShow();
      this.$api.Home.getHomeMenu({
        companyId: 0,
        userId: this.$utils.Store.getItem('userSerial'),
        spareCol1: 1,
        spareCol2: 0
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this5.$code.success) {
          _this5.$utils.Tools.loadHide();

          _this5.$utils.Tools.toastShow(msg);
        } else {
          _this5.navData = data;

          _this5.$utils.Store.setLocalItem('menuIndex', JSON.stringify(data));

          _this5.$utils.Tools.loadHide(); // this.noviceGuidance()

        }
      }).catch(function () {
        _this5.$utils.Tools.httpError();
      });
    },
    getHomeTopMenu: function getHomeTopMenu() {
      var _this6 = this;

      this.$utils.Tools.loadShow();
      this.$api.Home.getHomeMenu({
        companyId: 0,
        userId: this.$utils.Store.getItem('userSerial'),
        spareCol1: 0,
        spareCol2: 0
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this6.$code.success) {
          _this6.$utils.Tools.loadHide();

          _this6.$utils.Tools.toastShow(msg);
        } else {
          _this6.navTopData = data;

          if (_this6.navTopData && _this6.navTopData.length > 0) {
            _this6.headFlag = '1';
          } else {
            _this6.headFlag = '2';
          }

          _this6.$utils.Store.setLocalItem('menuTopIndex', JSON.stringify(data));

          _this6.$utils.Tools.loadHide();
        }
      }).catch(function () {
        _this6.$utils.Tools.httpError();
      });
    },
    pushHistory: function pushHistory() {
      var state = {
        title: 'title',
        url: ''
      };
      window.history.pushState(state, state.title, state.url);
    },
    initData: function initData() {
      // this.$utils.Store.removeItem('init');
      this.$utils.Store.removeItem('longitude');
      this.$utils.Store.removeItem('latitude');
      this.$utils.Store.removeItem('address');
      this.$utils.Store.removeItem('typeList');
    }
  },
  created: function created() {
    var _this7 = this;

    this.$api.Sys.getSysSetting().then(function (res) {
      _this7.title = res.title;
    });
    this.initData();
    this.navData = JSON.parse(this.$utils.Store.getLocalItem('menuIndex')) || [];
    this.navTopData = JSON.parse(this.$utils.Store.getLocalItem('menuTopIndex'));
    this.getHomeMenu();
    this.getHomeTopMenu();
    this.getMesageLen().then(this.getMesageData);
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {}
};